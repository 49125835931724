
import { defineComponent } from "vue";
import MonthItem from "@/components/LearningReport/participation/AltParticipationMonth.vue";

export default defineComponent({
  name: "AltParticipationYear",
  components: { MonthItem },
  props: ["year", "cardInfo","length"],
  methods: {
    setParticipation(num: number | null) {
      if (num == null) {
        return "0%";
      } else {
        return `${num}%`;
      }
    },
  },
  data() {
    return {
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
    };
  },
});
