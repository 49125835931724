
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import axios from "axios";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "CorrectAnswerTypeContent",
  components: {},
  mixins: [],
  props: ["info"],
  emits: [],
  data() {
    return {
      // 依序對應 10 ~ 90%
      arrayDescription: [
        "你的理解度似乎很低。加強複習錯誤答題解析、多參與基礎文法課程，並適時向老師提問請教，就能更快速地進步！",
        "你的理解度有待提高。繼續練習和複習答題解析、參與基礎文法課程，逐漸加強對內容的理解。",
        "你的理解度還有改善的空間。多加練習和學習，以提高答題正確率。",
        "你的理解度在不斷提高，但仍有一些內容需要更深入理解。可適時向老師提問請教，並針對困惑處多加練習。",
        "你的理解度達到了一半。繼續學習和複習，以增加正確答題的機會。",
        "你的理解度相對好，但仍有改進的空間。努力保持這個水平，並繼續提升。",
        "你的理解度高，大部分內容都掌握得不錯，答題解析和文法課程都能有效幫助你持續進步。",
        "你的理解度很好，大多數內容都能正確回答。繼續保持並努力提升。",
        "你的理解度非常高，大部分內容都掌握得很好，持續作題能往更好的層次前進。",
        "你的理解度出色，透過平日辛勤的練習，幾乎所有問題都能正確回答。可將心力移至其它進階目標發展。",
      ],
    };
  },
  computed: {
    rate() {
      let pieRate = 100 - this.info.hitRate;
      let correctRate = "";
      let content = "";
      switch (this.info.hitRate) {
        case 0: {
          correctRate = "10%以內";
          content =
            "你的理解度似乎很低。加強複習錯誤答題解析、多參與基礎文法課程，並適時向老師提問請教，就能更快速地進步！";
          break;
        }
        case 10: {
          correctRate = "10%~20%";
          content =
            "你的理解度有待提高。繼續練習和複習答題解析、參與基礎文法課程，逐漸加強對內容的理解。";
          break;
        }
        case 20: {
          correctRate = "20%~30%";
          content =
            "你的理解度還有改善的空間。多加練習和學習，以提高答題正確率。";
          break;
        }
        case 30: {
          correctRate = "30%~40%";
          content =
            "你的理解度在不斷提高，但仍有一些內容需要更深入理解。可適時向老師提問請教，並針對困惑處多加練習。";
          break;
        }
        case 40: {
          correctRate = "40%~50%";
          content =
            "你的理解度達到了一半。繼續學習和複習，以增加正確答題的機會。";
          break;
        }
        case 50: {
          correctRate = "50%~60%";
          content =
            "你的理解度相對好，但仍有改進的空間。努力保持這個水平，並繼續提升。";
          break;
        }
        case 60: {
          correctRate = "60%~70%";
          content =
            "你的理解度高，大部分內容都掌握得不錯，答題解析和文法課程都能有效幫助你持續進步。";
          break;
        }
        case 70: {
          correctRate = "70%~80%";
          content =
            "你的理解度很好，大多數內容都能正確回答。繼續保持並努力提升。";
          break;
        }
        case 80: {
          correctRate = "80%~90%";
          content =
            "你的理解度非常高，大部分內容都掌握得很好，持續作題能往更好的層次前進。";
          break;
        }
        case 90: {
          correctRate = "90%以上";
          content =
            "你的理解度出色，透過平日辛勤的練習，幾乎所有問題都能正確回答。可將心力移至其它進階目標發展。";
          break;
        }
        case 100: {
          correctRate = "100%";
          content =
            "你的理解度出色，透過平日辛勤的練習，幾乎所有問題都能正確回答。可將心力移至其它進階目標發展。";
          break;
        }
        default:
          break;
      }
      return { pieRate, correctRate, content };
    },
  },
  watch: {},
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    //
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    //
  },
});
