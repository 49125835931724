
import mixins from "@/mixins/index";
import { defineComponent } from "vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default defineComponent({
  name: "LessonRecordPagination",
  emits: ["pagechange"],
  props: ["activePage", "totalCount"],
  mixins: [mixins],
  components: {
    VPagination,
  },
  data() {
    return {
      startpage: 1,
      pages: 1,
    };
  },
  methods: {
    updateHandler(startpage: number) {
      this.$emit("pagechange", startpage);
    },
  },
  watch: {
    totalCount() {
      this.pages = Math.ceil(this.totalCount / 10);
    },
  },
  mounted() {
    // console.log(this.activePage)
    // console.log(this.totalCount);
  },
});
