
import { defineComponent } from "vue";
import axios from "axios";
// Mixins
import mixins from "@/mixins/index";
// Template
import Template518 from "@/views/templates/Template518.vue";
// Components
import CategoryCard from "@/components/LearningReport/CategoryCard.vue";
import LanguageSwitch from "@/components/LearningReport/LanguageSwitch.vue";
import UserCard from "@/components/LearningReport/UserCard.vue";
import PostRank from "@/components/LearningReport/PostRank.vue";
import CourseCountCard from "@/components/LearningReport/courseCount/CourseCountCard.vue";
import StudyCountCard from "@/components/LearningReport/studyCount/StudyCountCard.vue";
import ParticipationCard from "@/components/LearningReport/participation/ParticipationCard.vue";
import AltTopCard from "@/components/LearningReport/AltTopCard.vue";
import AnswerAnalysis from "@/components/LearningReport/MonthlyReport/AnswerAnalysis.vue";
import ClassRecord from "@/components/LearningReport/ClassRecord.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
interface AnalysisInfo {
  en: string;
  ch: string;
  hitRate: number;
  rightCount: number;
  totalCount: number;
  wrongCount: number;
}
interface yearItemInfo {
  year: number;
  data: {
    "12": number;
    "11": number;
    "10": number;
    "9": number;
    "8": number;
    "7": number;
    "6": number;
    "5": number;
    "4": number;
    "3": number;
    "2": number;
    "1": number;
  };
}
interface ArrayType {
  id: number;
  value: number;
}
export default defineComponent({
  name: "LearningReport",
  components: {
    Template518,
    CategoryCard,
    LanguageSwitch,
    UserCard,
    PostRank,
    CourseCountCard,
    StudyCountCard,
    ParticipationCard,
    AltTopCard,
    // AnalysisCard,
    AnswerAnalysis,
    ClassRecord,
  },
  mixins: [mixins],
  data() {
    return {
      // 預設值
      activeCategory: 0,
      activeLanguage: "en",
      activeRecord: "reserved",
      activeYear: 0,
      activeType: 0,
      // 來自後端
      userInfo: {},
      courseCountInfo: {},
      usedPercentageLength: 0,
      expirationDateLength: 0,
      lessonRecordInfo: [],
      studyCountInfo: {},
      participationInfo: [] as Array<yearItemInfo>,
      analysisInfo: [
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
        {
          en: "",
          ch: "",
          hitRate: 0,
          rightCount: 0,
          totalCount: 0,
          wrongCount: 0,
        },
      ] as Array<AnalysisInfo>,
      Edit_analysisInfo: [] as Array<number>,
      // 課程使用紀錄
      lessonRecordSetting: {
        recordTitle: "",
        activePage: 1,
        totalPageCount: 10,
      },
      cardItemTitle: [
        { id: 1, value: "ADV - 副詞" },
        { id: 2, value: "VER - 動詞" },
        { id: 3, value: "ADJ - 比較級,形容詞" },
        { id: 4, value: "MEW - 量詞" },
        { id: 5, value: "PRD - 冠詞,代詞" },
        { id: 6, value: "NOU - 單字,文意理解" },
        { id: 7, value: "BEE - be 動詞" },
        { id: 8, value: "MOD - 助動詞" },
        { id: 9, value: "CON - 假設句" },
        { id: 10, value: "CNJ - 連接詞" },
        { id: 11, value: "PRP - 介係詞用法" },
        { id: 12, value: "GER - 不定詞,動名詞... " },
        { id: 13, value: "XXX - 少見句型(倒裝...)" },
        { id: 14, value: "CAU - 使役動詞" },
        { id: 15, value: "REP - 間接句" },
        { id: 16, value: "PAS - 被動時態" },
        { id: 17, value: "CLA - 子句" },
        { id: 18, value: "ACT - 動詞時態" },
      ],
      arraySort: [
        { id: 1, value: 0 },
        { id: 2, value: 0 },
        { id: 3, value: 0 },
        { id: 4, value: 0 },
        { id: 5, value: 0 },
        { id: 6, value: 0 },
        { id: 7, value: 0 },
        { id: 8, value: 0 },
        { id: 9, value: 0 },
        { id: 10, value: 0 },
        { id: 11, value: 0 },
        { id: 12, value: 0 },
        { id: 13, value: 0 },
        { id: 14, value: 0 },
        { id: 15, value: 0 },
        { id: 16, value: 0 },
        { id: 17, value: 0 },
        { id: 18, value: 0 },
      ] as Array<ArrayType>,
      // 資料讀取中
      userCardDataLoagingCount: 0,
      userCardDataLoaging: true,
      rankDataLoaging: true,
      // 積分活動資料
      postRankData: {
        activityStatus: true,
        bannerType: "" as string | null,
        banner: "" as string | null,
        // 活動標題
        title: "OurScool 第二季「積分活動獎勵賽」火熱進行中！",
        // 活動敘述
        description: "積分活動獎勵賽 2023-03-01~2023-06-30，前往活動內容 >",
        // 手機板活動敘述
        descriptionMobile: [] as Array<string>,
        // 活動宣傳要導向的連結
        promotionalLink: "https://google.com",
        // 累計積分
        cumulativePoints: 12111,
        // 活動積分
        seasonalPoints: 6666,
        // 活動排名 %
        eventRanking: 25,
        // 領先百分比 %
        leadPercentage: 75,
        // 參與總人數
        participationCount: 99999,
        // 抽獎資格 %
        qualificationForLottery: 15,
        // 獎品名稱
        awardName: "任天堂Nintendo Switch 健身環大冒險同捆組",
        // 獎品圖片
        awardImg: "",
        // 倒數計時
        countdown: 0,
        // 活動截止日期
        eventDeadline: "2023-06-30 23:59止",
        // 活動截止日時間戳
        eventDeadlineTime: "",
        endTime: "",
      },
    };
  },
  methods: {
    // 課程使用紀錄
    // showRecord(classRecordService: number) {
    //   // 切換 Component
    //   this.activeCategory = 3;
    //   // 回覆初始化設定
    //   this.lessonRecordInfo = [];
    //   this.lessonRecordSetting.activePage = 1;
    //   // 設定該課程類型名稱
    //   this.lessonRecordSetting.recordTitle =
    //     this.classCategoryMap3(classRecordService);
    //   // 取得資料
    //   axios
    //     .get(`${serverUrl}history/record?service=${classRecordService}`, {
    //       headers: {
    //         Authorization: loginToken,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.status == 200) {
    //         this.lessonRecordInfo = res.data.data.info;
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(`錯誤： ${error}`);
    //     });
    // },

    arraySort_method() {
      //排序小---->大
      var preIndex, current;
      for (var i = 1; i < 18; i++) {
        preIndex = i - 1;
        current = this.arraySort[i];
        while (
          preIndex >= 0 &&
          this.arraySort[preIndex].value > current.value
        ) {
          this.arraySort[preIndex + 1] = this.arraySort[preIndex];
          preIndex--;
        }
        this.arraySort[preIndex + 1] = current;
      }
      //將數據傳到父組件LearningReport.vue裡顯示
      // this.$emit('AnalysisCard_barvalue', this.barValue)
    },

    updateYear(year: number) {
      this.activeYear = year;
    },
    updateCategory(activeCategory: number) {
      this.activeCategory = activeCategory;
      // Go to top when page loaded
      window.scrollTo(0, 0);
    },
    updateLanguage(activeLanguage: string) {
      // console.log(activeLanguage);
      this.activeLanguage = activeLanguage;
      const newLang = this.activeLanguage;
      // 更新「學習檔案」資料：服務時程
      axios
        .get(`${serverUrl}history/course?subject=${newLang}`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let screenWidth = window.innerWidth;
            if (screenWidth >= 768) {
              let buffer = [];
              buffer.push(res.data.data.usedPercentage[0]);
              buffer.push(res.data.data.usedPercentage[2]);
              buffer.push(res.data.data.usedPercentage[1]);
              buffer.push(res.data.data.usedPercentage[3]);
              buffer.push(res.data.data.usedPercentage[4]);
              buffer.push(res.data.data.usedPercentage[7]);
              buffer.push(res.data.data.usedPercentage[6]);
              buffer.push(res.data.data.usedPercentage[9]);
              buffer.push(res.data.data.usedPercentage[5]);
              buffer.push(res.data.data.usedPercentage[8]);
              res.data.data.usedPercentage = buffer;
            }
            // 服務 title 加入
            res.data.data.usedPercentage.forEach(function (
              item: any,
              index: any,
              array: any
            ) {
              if (item.service === 1) {
                item.title = "週課";
              } else if (item.service === 2) {
                item.title = "週課 (托福雅思)";
              } else if (item.service === 3) {
                item.title = "月課";
              } else if (item.service === 7) {
                item.title = "家教課";
              } else if (item.service === 9) {
                item.title = "1對1視訊 (英日)";
              } else if (item.service === 10) {
                item.title = "1對1視訊 (特殊語言)";
              } else if (item.service === 11) {
                item.title = "1對1視訊 (托福雅思)";
              } else if (item.service === 14) {
                item.title = "1對1視訊 (C幣-英日)";
              } else if (item.service === 15) {
                item.title = "1對1視訊 (C幣-特殊語言)";
              } else if (item.service === 16) {
                item.title = "1對1視訊 (C幣-托福雅思)";
              }
            });
            // 只保留 total > 0 的服務， total = 0 代表學生沒有購買該服務，因此移除不顯示
            res.data.data.usedPercentage = res.data.data.usedPercentage.filter(
              (item: {
                service: number;
                used: number;
                total: number;
                title: string;
              }) => item.total !== 0
            );
            let expirationDateBuffer = [];
            expirationDateBuffer[0] = {
              title: "Ai 互動學習",
              date: res.data.data.expirationDate[0],
            };
            expirationDateBuffer[1] = {
              title: "問答家教 (英日)",
              date: res.data.data.expirationDate[1],
            };
            expirationDateBuffer[2] = {
              title: "問答家教 (特殊語言)",
              date: res.data.data.expirationDate[2],
            };
            expirationDateBuffer[3] = {
              title: "問答家教 (托福雅思)",
              date: res.data.data.expirationDate[3],
            };
            expirationDateBuffer = expirationDateBuffer.filter(
              (item: { title: string; date: string }) => item.date !== "--"
            );
            res.data.data.expirationDate = [];
            res.data.data.expirationDate = expirationDateBuffer;
            this.courseCountInfo = res.data.data;
            this.usedPercentageLength = res.data.data.usedPercentage.length;
            this.expirationDateLength = res.data.data.expirationDate.length;
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
        });

      if (newLang !== "jp") {
        let date: {
          year: number | string;
          month: number | string;
          firstDay: number | string;
          lastDay: number | string;
        } = this.getLastDate();
        let monthBuffer = "" as string | number;
        if (date.month.toString().length === 1) {
          monthBuffer = `0${date.month}`;
        } else {
          monthBuffer = date.month;
        }
        axios
          .get(
            `${serverUrl}history/analysis?subject=${newLang}&date=${date.year}-${monthBuffer}`,
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.analysisInfo = res.data.data;
              this.analysisInfo.forEach((item: any) => {
                item.hitRate = this.rateProcessing(item.hitRate);
              });
            }
            this.arraySort_method();
          })
          .catch((error) => {
            console.error(`錯誤： ${error}`);
          });
      } else {
        this.analysisInfo = [
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
          {
            en: "",
            ch: "",
            hitRate: 0,
            rightCount: 0,
            totalCount: 0,
            wrongCount: 0,
          },
        ];
      }
    },
    rankDataGet(id: number) {
      this.rankDataLoaging = true;
      axios
        .get(`${serverUrl}point/${id}`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let buffer = res.data.data;
            // 活動狀態
            this.postRankData.activityStatus = buffer.active;
            // 活動標題
            this.postRankData.title = buffer.eventTitle;
            // 活動 banner
            if (buffer.eventBanner === null) {
              this.postRankData.bannerType = null;
              this.postRankData.banner = null;
            } else {
              this.postRankData.bannerType = buffer.eventBanner.type;
              // 積分制度 banner 判斷
              if (buffer.eventBanner.type === "image") {
                let screenWidth = window.innerWidth;
                let imgObj: { [key: string]: string } = {
                  XS: "",
                  S: "",
                  M: "",
                  L: "",
                  XL: "",
                  XXL: "",
                };
                buffer.eventBanner.data.forEach((item: any, index: number) => {
                  imgObj[item.size] = item.image;
                });
                if (screenWidth >= 1440) {
                  this.postRankData.banner = imgObj.XXL;
                } else if (screenWidth >= 1280 && screenWidth <= 1439) {
                  this.postRankData.banner = imgObj.XL;
                } else if (screenWidth >= 1024 && screenWidth <= 1279) {
                  this.postRankData.banner = imgObj.L;
                } else if (screenWidth >= 768 && screenWidth <= 1023) {
                  this.postRankData.banner = imgObj.M;
                } else if (screenWidth >= 415 && screenWidth <= 767) {
                  this.postRankData.banner = imgObj.S;
                } else if (screenWidth <= 414) {
                  this.postRankData.banner = imgObj.XS;
                }
              } else if (buffer.eventBanner.type === "color") {
                this.postRankData.banner = buffer.eventBanner.data[0];
              }
            }
            // 抽獎資格
            this.postRankData.qualificationForLottery = buffer.eligibility;
            // 活動連結
            this.postRankData.promotionalLink = buffer.eventUrl;
            // 開始時間
            let startTime = this.formatDate(buffer.startDate);
            // 結束時間
            let endTime = this.formatDate(buffer.endDate);
            this.postRankData.endTime = endTime;
            // 活動說明
            this.postRankData.description = `積分活動獎勵賽 ${startTime}~${endTime}, 前往活動內容 >`;
            // 活動說明手機板
            this.postRankData.descriptionMobile = [
              "積分活動獎勵賽",
              `${startTime}~${endTime}`,
              "前往活動內容 >",
            ];
            // 用戶總積分
            this.postRankData.cumulativePoints = buffer.userTotalPoint;
            // 用戶活動積分
            this.postRankData.seasonalPoints = buffer.userEventPoint;
            // 用戶當前排名百分比
            this.postRankData.eventRanking = buffer.userPercentileRank;
            // 已領先多少百分比的用戶
            this.postRankData.leadPercentage = 100 - buffer.userPercentileRank;
            // 活動參加人數
            if (buffer.active === true) {
              this.postRankData.participationCount = buffer.participantCount;
            } else {
              this.postRankData.participationCount = 0;
            }
            // 獎品名稱
            this.postRankData.awardName = buffer.prize;
            // 獎品圖片
            this.postRankData.awardImg = buffer.prizeImage;
            // 活動截止日
            this.postRankData.eventDeadline = this.formatDateTime(
              buffer.endDate
            );
            // 活動截止日時間戳轉換
            this.postRankData.eventDeadlineTime = this.convertDateFormat(
              this.postRankData.eventDeadline
            );
            this.rankDataLoaging = false;
          }
        })
        .catch((err) => {
          console.log(`catch觸發 :${err}`);
          // this.isPending = false;
        });
    },
    userIdGet() {
      axios
        .get(`${serverUrl}personal`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            // 積分活動資料取得
            this.rankDataGet(res.data.data.user.id);
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
        });
    },
    formatDate(input: string): string {
      if (input === null) {
        return "";
      }
      const parts = input.split(" ")[0].split("-");
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];

      return `${year}/${month}/${day}`;
    },
    formatDateTime(input: string): string {
      if (input === null) {
        return "";
      }
      const parts = input.split(":");
      const dateTime = parts.slice(0, 2).join(":");

      return dateTime;
    },
    convertDateFormat(dateTimeString: string): string {
      const convertedDateTimeString = dateTimeString.replace(/-/g, "/");
      return convertedDateTimeString;
    },
    rateProcessing(rate: number) {
      let mappedNumber = Math.floor(rate / 10) * 10;
      return mappedNumber;
    },
  },
  watch: {
    pageRoute: {
      handler: function () {
        this.activeCategory = parseInt(`${this.$route.query.category}`);
        if (this.activeCategory == 3) {
          this.activeType = parseInt(`${this.$route.query.type}`);
          // 切換 Component
          this.activeCategory = 3;
          // 回覆初始化設定
          this.lessonRecordInfo = [];
          this.lessonRecordSetting.activePage = 1;
          // 設定該課程類型名稱
          this.lessonRecordSetting.recordTitle = this.classCategoryMap3(
            parseInt(`${this.$route.query.type}`)
          );
          // 取得資料
          axios
            .get(
              `${serverUrl}history/record?service=${parseInt(
                `${this.$route.query.type}`
              )}`,
              {
                headers: {
                  Authorization: loginToken,
                },
              }
            )
            .then((res) => {
              if (res.status == 200) {
                this.lessonRecordInfo = res.data.data.info;
              }
            })
            .catch((error) => {
              console.error(`錯誤： ${error}`);
            });
        }
      },
    },
    userCardDataLoagingCount() {
      if (this.userCardDataLoagingCount == 0) {
        this.userCardDataLoaging = false;
      } else {
        this.userCardDataLoaging = true;
      }
    },
  },
  computed: {
    pageRoute() {
      return this.$route.query.category;
    },
  },
  mounted() {
    this.getLastDate();
    if (this.$route.query.category !== undefined) {
      this.activeCategory = 0;
      if (parseInt(`${this.$route.query.category}`) == 3) {
        // 切換 Component
        this.activeCategory = 3;
        // 回覆初始化設定
        this.lessonRecordInfo = [];
        this.lessonRecordSetting.activePage = 1;
        // 設定該課程類型名稱
        this.lessonRecordSetting.recordTitle = this.classCategoryMap3(
          parseInt(`${this.$route.query.type}`)
        );
        // 取得資料
        axios
          .get(
            `${serverUrl}history/record?service=${parseInt(
              `${this.$route.query.type}`
            )}`,
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.lessonRecordInfo = res.data.data.info;
            }
          })
          .catch((error) => {
            console.error(`錯誤： ${error}`);
          });
      }
    } else {
      this.activeCategory = parseInt(`${this.$route.query.category}`);
    }
    // 使用者資訊
    this.userCardDataLoagingCount = this.userCardDataLoagingCount + 1;
    axios
      .get(`${serverUrl}common/user`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.userInfo = res.data.data.userInfo;
          this.userCardDataLoagingCount = this.userCardDataLoagingCount - 1;
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
    // 每月參與度
    axios
      .get(`${serverUrl}history/participation`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.participationInfo = res.data.data.participationInfo;
          this.activeYear = this.participationInfo[0].year;
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
    // 英文服務時程
    axios
      .get(`${serverUrl}history/course?subject=${this.activeLanguage}`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          let screenWidth = window.innerWidth;
          if (screenWidth >= 768) {
            let buffer = [];
            buffer.push(res.data.data.usedPercentage[0]);
            buffer.push(res.data.data.usedPercentage[2]);
            buffer.push(res.data.data.usedPercentage[1]);
            buffer.push(res.data.data.usedPercentage[3]);
            buffer.push(res.data.data.usedPercentage[4]);
            buffer.push(res.data.data.usedPercentage[7]);
            buffer.push(res.data.data.usedPercentage[6]);
            buffer.push(res.data.data.usedPercentage[9]);
            buffer.push(res.data.data.usedPercentage[5]);
            buffer.push(res.data.data.usedPercentage[8]);
            res.data.data.usedPercentage = buffer;
          }
          // 服務 title 加入
          res.data.data.usedPercentage.forEach(function (
            item: any,
            index: any,
            array: any
          ) {
            if (item.service === 1) {
              item.title = "週課";
            } else if (item.service === 2) {
              item.title = "週課 (托福雅思)";
            } else if (item.service === 3) {
              item.title = "月課";
            } else if (item.service === 7) {
              item.title = "家教課";
            } else if (item.service === 9) {
              item.title = "1對1視訊 (英日)";
            } else if (item.service === 10) {
              item.title = "1對1視訊 (特殊語言)";
            } else if (item.service === 11) {
              item.title = "1對1視訊 (托福雅思)";
            } else if (item.service === 14) {
              item.title = "1對1視訊 (C幣-英日)";
            } else if (item.service === 15) {
              item.title = "1對1視訊 (C幣-特殊語言)";
            } else if (item.service === 16) {
              item.title = "1對1視訊 (C幣-托福雅思)";
            }
          });
          // 只保留 total > 0 的服務， total = 0 代表學生沒有購買該服務，因此移除不顯示
          res.data.data.usedPercentage = res.data.data.usedPercentage.filter(
            (item: {
              service: number;
              used: number;
              total: number;
              title: string;
            }) => item.total !== 0
          );
          let expirationDateBuffer = [];
          expirationDateBuffer[0] = {
            title: "Ai 互動學習",
            date: res.data.data.expirationDate[0],
          };
          expirationDateBuffer[1] = {
            title: "問答家教 (英日)",
            date: res.data.data.expirationDate[1],
          };
          expirationDateBuffer[2] = {
            title: "問答家教 (特殊語言)",
            date: res.data.data.expirationDate[2],
          };
          expirationDateBuffer[3] = {
            title: "問答家教 (托福雅思)",
            date: res.data.data.expirationDate[3],
          };
          expirationDateBuffer = expirationDateBuffer.filter(
            (item: { title: string; date: string }) => item.date !== "--"
          );
          res.data.data.expirationDate = [];
          res.data.data.expirationDate = expirationDateBuffer;
          this.courseCountInfo = res.data.data;
          this.usedPercentageLength = res.data.data.usedPercentage.length;
          this.expirationDateLength = res.data.data.expirationDate.length;
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
    // 學習時間累計
    axios
      .get(`${serverUrl}history/study`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.studyCountInfo = res.data.data.studyCountInfo;
          // 測試用資料， API 修改後就可以拿掉 ↓
          // let monthlyCountBuffer = res.data.data.studyCountInfo.monthlyCount;
          // let totalCountBuffer = res.data.data.studyCountInfo.totalCount;
          // delete totalCountBuffer.rating;
          // delete totalCountBuffer.grammar;
          // totalCountBuffer.week = 666;
          // totalCountBuffer.month = 6666;
          // delete monthlyCountBuffer.rating;
          // delete monthlyCountBuffer.grammar;
          // monthlyCountBuffer.week = 333;
          // monthlyCountBuffer.month = 3333;
          // 測試用資料， API 修改後就可以拿掉 ↑
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
    // AI題型分析
    let date: {
      year: number | string;
      month: number | string;
      firstDay: number | string;
      lastDay: number | string;
    } = this.getLastDate();
    let monthBuffer = "" as string | number;
    if (date.month.toString().length === 1) {
      monthBuffer = `0${date.month}`;
    } else {
      monthBuffer = date.month;
    }
    axios
      .get(
        `${serverUrl}history/analysis?subject=${this.activeLanguage}&date=${date.year}-${monthBuffer}`,
        {
          headers: {
            Authorization: loginToken,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          this.analysisInfo = res.data.data;
          this.analysisInfo.forEach((item: any) => {
            item.hitRate = this.rateProcessing(item.hitRate);
          });
        } else {
          this.analysisInfo = [
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
            {
              en: "",
              ch: "",
              hitRate: 0,
              rightCount: 0,
              totalCount: 0,
              wrongCount: 0,
            },
          ];
        }
        this.arraySort_method();
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
    // 切換 Tab
    if (this.$route.query.category) {
      this.activeCategory = parseInt(`${this.$route.query.category}`);
    } else {
      this.activeCategory = 0;
    }
    // 用戶 id 取得
    this.userIdGet();
  },
});
