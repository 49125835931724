
import { defineComponent } from "vue";
import mixins from "@/mixins/index";
import Bus from "@/utils/Bus";

import StudyCountCardItem from "@/components/LearningReport/studyCount/StudyCountCardItem.vue";
import AltParticipationCard from "@/components/LearningReport/participation/AltParticipationCard.vue";

// interface StudyCountInfo {

// }

interface learningProject {
  title: string;
  value: number;
  unit: string;
  id: number;
}
export default defineComponent({
  name: "LearningReportStudyCount",
  components: { StudyCountCardItem, AltParticipationCard },
  mixins: [mixins],
  props: ["cardInfo", "participationInfo"],
  data() {
    return {
      cardItem: [
        {
          name: "week",
          title: "週課",
          unit: "分鐘",
        },
        {
          name: "month",
          title: "月課",
          unit: "分鐘",
        },
        {
          name: "oneOnone",
          title: "1對1視訊課",
          unit: "分鐘",
        },
        {
          name: "speaking",
          title: "家教課",
          unit: "分鐘",
        },
        {
          name: "exerciseCount",
          title: "Ai 做題數",
          unit: "題",
        },
        {
          name: "exercisePercentage",
          title: "Ai 參與率",
          unit: "%",
        },
        {
          name: "login",
          title: "登入",
          unit: "次",
        },
        {
          name: "evaluation",
          title: "程度測驗",
          unit: "次",
        },
      ],
      totals: [] as Array<learningProject>,
      everyMonth: [] as Array<learningProject>,
      // 跟後端要的
      donutData: {
        used: 20,
        total: 160,
      },
      // 自行設定
      donutSetting: {
        title: "總計學習時間",
        unit: "分鐘",
        // size: "180px",
      },
      isRotated: false,
    };
  },
  methods: {
    toggleAI() {
      // console.log("DONE");
      Bus.emit("toggleWidget", "AiQuiz");
    },
    toggleRotation() {
      this.isRotated = !this.isRotated;
    },
    processCounts(source: any, target: any) {
      for (let i = 0; i < Object.keys(source).length; i++) {
        let buffer = {
          title: this.cardItem[i].title,
          value: source[this.cardItem[i].name],
          unit: this.cardItem[i].unit,
          id: i,
        };
        if (buffer.title === "月課" && buffer.value === 0) {
          continue;
        }
        target.push(buffer);
      }
    },
  },
  computed: {
    getLastMonth() {
      const d = new Date();
      let month = d.getMonth();
      let length = month.toString().length;
      if (month == 0) {
        // 1 月
        return "12";
      } else {
        if (length === 1) {
          return `0${month}`;
        } else {
          return `${month}`;
        }
      }
    },
    getLastMonthEnd() {
      var thisYear = new Date().getFullYear();
      var longerMonth = ["01", "03", "05", "07", "08", "10", "12"];

      if (this.getLastMonth == "02") {
        if ((thisYear % 4 == 0 && thisYear % 100 != 0) || thisYear % 400 == 0) {
          return "29";
        } else {
          return "28";
        }
      } else if (longerMonth.includes(this.getLastMonth)) {
        return "31";
      } else {
        return "30";
      }
    },
  },
  watch: {
    cardInfo: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && Object.keys(newValue).length > 0) {
          this.processCounts(newValue.totalCount, this.totals);
          this.processCounts(newValue.monthlyCount, this.everyMonth);
        }
      },
    },
  },
});
