
import { defineComponent } from "vue";

export default defineComponent({
  name: "AnswerContentAllCorrect",
  components: {},
  mixins: [],
  props: ["answerStatus", "info"],
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    //
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {},
});
