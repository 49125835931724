import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05433d92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "analysis-card-word text-black-secondary" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "fw-bold" }
const _hoisted_5 = { class: "analysis-card-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row g-0 analysis-card", { 'content-gap': _ctx.index !== -1, 'first-card': _ctx.index == -1 }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.itemTitle), 1),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.itemStats) + "%", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "analysis-card-bar-color bar-color-1",
          style: _normalizeStyle([_ctx.coloredBar(_ctx.itemStats !== undefined ? _ctx.itemStats : 0), {"transition":"width 0.5s"}])
        }, null, 4),
        _createElementVNode("div", {
          style: _normalizeStyle([
            _ctx.whiteBar(
              _ctx.itemStats !== undefined ? (_ctx.itemStats > 0 ? _ctx.itemStats : -2) : 0
            )
          , {"transition":"width 0.5s"}])
        }, null, 4)
      ])
    ])
  ], 2))
}