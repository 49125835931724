
import { defineComponent } from "vue";
import Bus from "@/utils/Bus";
export default defineComponent({
  name: "AnswerContentEmpty",
  components: {},
  mixins: [],
  props: [],
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    //
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    openModal() {
      Bus.emit("toggleWidget", "AiQuiz");
    },
  },
});
