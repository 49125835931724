import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49916fbf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "month-item d-flex" }
const _hoisted_3 = { class: "text-black-900 mounth-font" }
const _hoisted_4 = { class: "text-black-900 month-item-num-font month-item-num" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.month), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.cardInfo), 1)
    ])
  ]))
}