
import { defineComponent } from "vue";
import LexicalCategory from "@/components/modal/LexicalCategory.vue";
import FilterOption from "@/components/LearningReport/MonthlyReport/FilterOption.vue";
import { Modal } from "bootstrap";
import mixins from "@/mixins/index";

export default defineComponent({
  name: "CorrectAnswerRate",
  components: { LexicalCategory, FilterOption },
  mixins: [mixins],
  props: ["averageAnswerRate", "filterData", "selectedTitle"],
  emits: ["selectFilter"],
  data() {
    return {
      modalId: "CorrectAnswerLexicalCategoryModal",
      myModal: null as any,
    };
  },
  computed: {
    lastMonth() {
      let date = this.getLastDate();
      return date.month;
    },
  },
  watch: {
    //
  },
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    this.myModal = new Modal(
      document.getElementById(`${this.modalId}`) as HTMLElement
    );
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    showModal() {
      this.myModal.show();
    },
    selectFilter(category: string, title: string, id: number) {
      this.$emit("selectFilter", category, title, id);
    },
  },
});
