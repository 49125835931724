import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ef9e4e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-card-right-col-div" }
const _hoisted_2 = { class: "page-word text-black-800" }
const _hoisted_3 = { class: "page-word" }
const _hoisted_4 = {
  class: "text-primary-default",
  style: {"font-weight":"500"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.year) + " / " + _toDisplayString(_ctx.month) + "月", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.itemInfo), 1)
    ])
  ]))
}