
import { defineComponent } from "vue";
import axios from "axios";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface actionAndPoints {
  action: string;
  point: string;
}

export default defineComponent({
  name: "RankRule",
  components: {},
  props: ["id"],
  data() {
    return {
      actionAndPointsAndDescriptions: [] as Array<any>,
      actionAndPoints: [] as Array<actionAndPoints>,
      descriptions: [] as Array<string>,
    };
  },
  methods: {
    getRankRule() {
      axios
        .get(`${serverUrl}point/rule`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          let result = res.data.data;
          result.forEach((item: any) => {
            // 1024px 以上資料結構
            let dataBuffer = {
              action: "",
              point: "",
              description: "",
            };
            dataBuffer.action = item.action;
            dataBuffer.point = item.point;
            dataBuffer.description = item.description;
            this.actionAndPointsAndDescriptions.push(dataBuffer);

            // 1024px 以下資料結構
            let buffer = {
              action: "",
              point: "",
            };
            buffer.action = item.action;
            buffer.point = item.point;
            this.actionAndPoints.push(buffer);
            this.descriptions.push(item.description);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    gradientEffect() {
      let target = document.querySelector(`#fade-content`)! as HTMLElement;
      let scrollBody = document.querySelector(`#scroll-body`)! as HTMLElement;
      // 這裡必須延遲 10ms 是為了避免 DOM 剛生成時無法正確取得高度
      setTimeout(() => {
        scrollBody.addEventListener("scroll", () => {
          // 多 +5 是為了避免誤差造成判斷錯誤
          if (
            scrollBody.scrollTop + scrollBody.clientHeight + 5 >=
            scrollBody.scrollHeight
          ) {
            target.classList.remove("fade-content");
            target.classList.add("no-fade-content");
          } else {
            target.classList.add("fade-content");
            target.classList.remove("no-fade-content");
          }
        });
      }, 10);
    },
  },
  mounted() {
    this.getRankRule();
    this.gradientEffect();
  },
});
