
import { defineComponent } from "vue";
import AnswerTitleSelector from "@/components/LearningReport/MonthlyReport/AnswerTitleSelector.vue";
import AnswerContent from "@/components/LearningReport/MonthlyReport/AnswerContent.vue";
import AnswerContentAllCorrect from "@/components/LearningReport/MonthlyReport/AnswerContentAllCorrect.vue";
import AnswerContentEmpty from "@/components/LearningReport/MonthlyReport/AnswerContentEmpty.vue";
import CorrectAnswerRateTitle from "@/components/LearningReport/MonthlyReport/CorrectAnswerRateTitle.vue";
import CorrectAnswerRateContent from "@/components/LearningReport/MonthlyReport/CorrectAnswerRateContent.vue";
import CourseCard from "@/components/HomePage/CourseCard.vue";
import CourseReserveContent from "@/components/modal/CourseReserveContent.vue";
import { Modal } from "bootstrap";
import axios from "axios";
import mixins from "@/mixins/index";
import Bus from "@/utils/Bus";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import {
  ailearnCategoryGet,
  ailearnRecordGet,
  systemPreferenceGet,
  systemPreferenceSet,
} from "@/api/index";
import "@/assets/scss/homePage/splide.css";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
interface course {
  canReserve: boolean;
  // classDate 一開始從 API 收到會是時間戳，但畫面上要顯示日期跟時間，因此會轉換成字串
  startClassDate: number | string;
  endClassDate: number | string;
  classId: number;
  cnName: string;
  description: string;
  foreignName: string;
  isReserved: boolean;
  keyVisual: string;
  label: string | null;
  level: number | null;
  options: {
    add_on_video: boolean;
    review_video: boolean;
    course_material: boolean;
  };
  password: string | null;
  photo: string;
  rating: number;
  roomLink: string | null;
  subject: string;
  teacherId: number;
  title: string;
  type: string;
}
interface remark {
  name: string;
  value: string | null;
}
interface LearnCategory {
  en: Category[];
  jp: Category[];
}
interface Category {
  chinese: string;
  category: string;
}
export default defineComponent({
  name: "AnswerAnalysis",
  components: {
    AnswerTitleSelector,
    AnswerContent,
    CorrectAnswerRateTitle,
    CorrectAnswerRateContent,
    CourseCard,
    Splide,
    SplideSlide,
    SplideTrack,
    CourseReserveContent,
    AnswerContentEmpty,
    AnswerContentAllCorrect,
  },
  mixins: [mixins],
  props: ["cardInfo", "activeLanguage"],
  emits: [],
  data() {
    return {
      answerAnalysisSplideOptions: {
        perPage: 1,
        perMove: 1,
        pagination: false,
        arrows: false,
        start: -1,
      },
      recommendedCourseSplideOptions: {
        perPage: 1,
        perMove: 1,
        pagination: false,
        flickPower: 100,
      },
      isPending: true,
      classData: {
        subjectImg: "" as string | null,
        teacherImg: "" as string | null,
        teacherId: 0 as number | null,
        time: "" as string | null,
        feature: "" as string | null,
        classType: "" as string | null,
        level: null as string | null,
        title: "" as string | null,
        content: "" as string | null,
        remark: [] as remark[],
        status: 0,
        link: "" as string | null,
        password: "" as string | null,
        teacherName: "" as string | null,
        type: "",
        subject: "",
        id: 0,
        timestamp: 0,
      },
      courseInfosOrigin: [] as any,
      courseInfos: [] as any,
      myModal: null as any,
      modalId: "reconnended-course",
      leftOn: false,
      rightOn: false,
      learnCategory: {} as any,
      // 答題記錄需要的資料 ↓
      AnswerTitleFilter: {
        filterOptions: [
          { title: "全部題目", id: 0, selected: false, category: "ALL" },
        ],
        filterSelected: "",
        filterIndex: 0,
      },
      // 從 API 取得的所有問題
      allQuestionContent: [] as any,
      // 顯示在畫面上的問題，可能被題型過濾器限制，因此要另外開一個陣列
      actualQuestionContent: [] as any,
      // 目前被選擇到的題目
      questionSelectedIndex: 0,
      // 總題數、答對題數、答錯題數
      questionQuantity: {
        total: 0,
        correct: 1,
        wrong: 2,
      },
      onlyWrongStatus: false,
      answerCategory: "ALL",
      selectedTitle: "全部題目",
      allCorrectTitle: "",
      // 答題記錄需要的資料 ↑
      // 答題正確分析率需要的資料 ↓
      analysisRateFilter: {
        filterOptions: [
          { title: "18類題型分析", id: 0, selected: false, category: "ALL" },
        ],
        filterSelected: "",
        filterIndex: 0,
      },
      analysisRateSelectedTitle: "",
      analysisRateTitle: "18類題型分析",
      // 答題正確分析率需要的資料 ↑
      // 這個狀態用來避免因 watch 答題解析 filter 同時 call 太多次 API
      systemPreferenceSetting: false,
    };
  },
  computed: {
    answerTitleStatus() {
      // 正常顯示
      if (this.actualQuestionContent.length > 0) {
        return {
          selectors: true,
          type: true,
          analyzeBlock: true,
          notAnswerYet: false,
          allCorrect: false,
        };
      }
      // 該類型沒有答錯
      else if (this.actualQuestionContent.length === 0) {
        return {
          selectors: false,
          type: true,
          analyzeBlock: false,
          notAnswerYet: false,
          allCorrect: true,
        };
      } else {
        return {
          selectors: true,
          type: true,
          analyzeBlock: true,
          notAnswerYet: false,
          allCorrect: false,
        };
      }
      // // 尚未作答
      // if (status) {
      //   return {
      //     selectors: false,
      //     type: false,
      //     analyzeBlock: false,
      //     notAnswerYet: true,
      //     allCorrect: false,
      //   };
      // }
    },
    averageAnswerRate() {
      let result = 0;
      this.cardInfo.forEach((item: any) => {
        result = result + item.hitRate;
      });
      result = result / this.cardInfo.length;

      return Math.round(result);
    },
    routeCategory() {
      return this.$route.query.category;
    },
  },
  watch: {
    // questionSelectedIndex() {
    //   this.answerAnalysisSplideOptions.start = this.questionSelectedIndex;
    // },
    // 監視到勾選或取消 "只顯示答錯題目" 時，要針對目前顯示的題目陣列修改
    onlyWrongStatus() {
      this.actualQuestionContent = this.questionsDataProcessing(
        this.allQuestionContent,
        this.onlyWrongStatus,
        this.answerCategory
      );
      setTimeout(() => {
        this.systemPreferenceSetLimit();
      }, 100);
    },
    answerCategory() {
      setTimeout(() => {
        this.systemPreferenceSetLimit();
      }, 100);
    },
    selectedTitle() {
      setTimeout(() => {
        this.systemPreferenceSetLimit();
      }, 100);
    },
    questionSelectedIndex() {
      setTimeout(() => {
        this.systemPreferenceSetLimit();
      }, 100);
    },
    routeCategory() {
      if (this.routeCategory === "1") {
        this.answerFilterRestoreTrigger();
      }
    },
  },
  beforeCreate() {
    //
  },
  created() {
    this.courseGet();
    this.ailearnCategoryGet().then((res) => {
      let arraySort = res?.data.data.en.sort((a: any, b: any) => {
        const categoryA = a.category.toLowerCase();
        const categoryB = b.category.toLowerCase();

        if (categoryA < categoryB) {
          return -1;
        }
        if (categoryA > categoryB) {
          return 1;
        }
        return 0;
      });
      let buffer = arraySort.map((item: any, index: any) => {
        return {
          title: `${item.category} - ${item.chinese}`,
          // 需要 +1 的原因是起始都會有一筆資料佔用預設
          id: index + 1,
          selected: false,
          category: item.category,
        };
      });
      this.learnCategory = buffer;
      this.AnswerTitleFilter.filterOptions = [
        ...this.AnswerTitleFilter.filterOptions,
        ...buffer,
      ];
      this.analysisRateFilter.filterOptions = [
        ...this.analysisRateFilter.filterOptions,
        ...buffer,
      ];
    });
    this.ailearnRecordGet().then((res) => {
      this.allQuestionContent = res?.data.data.record;

      let total = this.allQuestionContent.length;
      let correct = 0;
      let wrong = 0;
      this.allQuestionContent.forEach((item: any) => {
        if (item.isCurrent) {
          correct++;
        } else {
          wrong++;
        }
      });
      // 計算答對及答錯題目個幾題
      this.questionQuantity.total = total;
      this.questionQuantity.correct = correct;
      this.questionQuantity.wrong = wrong;
      // 將取得的資料處理成需要格式
      this.actualQuestionContent = this.questionsDataProcessing(
        this.allQuestionContent,
        this.onlyWrongStatus,
        this.answerCategory
      );
    });
  },
  beforeMount() {
    //
  },
  mounted() {
    this.myModal = new Modal(
      document.getElementById(`modal-${this.modalId}`) as HTMLElement
    );
    this.splidePerPageCalc();
    addEventListener("resize", () => {
      this.splidePerPageCalc();
    });
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    courseInfoConvert(data: Array<course>) {
      let info = data.map((item: course) => {
        let buffer = item;
      });
    },
    openCourseModal(index: number) {
      const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
      axios
        .get(`${serverUrl}normal`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.classData = this.courseReserveDataConvert(
              this.courseInfosOrigin[index]
            );
            this.myModal.show();
          }
        })
        .catch((error) => {
          Bus.emit("loginStatus", false);
          Bus.emit("openLoginModal");
        });
    },
    closeModal(text: string, id: number) {
      if (text == "close") {
        this.myModal.hide();
      } else {
        this.myModal.hide();
      }
    },
    updateClassStatus(id: number) {
      this.courseGet();
    },
    classStatusCalc(startTime: string, endTime: string, isReserved: boolean) {
      // 當可預約狀態 === false ，代表尚未預約該課程
      if (isReserved === false || isReserved === undefined) {
        return 1;
        // 當可預約狀態 === true 時，才需要判斷目前是否可以進入教室
      } else {
        const currentTime = new Date();
        // 除以 1000 的用意是將時間戳從毫秒轉換成秒
        const currentTimeStamp = parseInt(
          (currentTime.getTime() / 1000).toFixed()
        );
        // startTime , endTime 來自上面計算時間時一起換算的結果
        // 開始時間戳要 -600 原因是要提早十分鐘開啟進入教室， 600 = 60*10 ,時間戳單位是秒
        const startTimeStamp =
          parseInt((Date.parse(startTime) / 1000).toFixed()) - 600;
        const endTimeStamp = parseInt((Date.parse(endTime) / 1000).toFixed());
        if (currentTimeStamp < startTimeStamp) {
          return 2; // 已預約
        } else if (
          currentTimeStamp >= startTimeStamp &&
          currentTimeStamp <= endTimeStamp
        ) {
          return 3; // 可進入教室
        } else if (currentTimeStamp > endTimeStamp) {
          return 4; // 課程已結束
        }
      }
    },
    courseGet() {
      this.isPending = true;
      const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
      axios
        .get(`${serverUrl}course/recommend`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.courseInfosOrigin = [];
            this.courseInfos = [];
            // 將取得的資料保存
            this.courseInfosOrigin = JSON.parse(JSON.stringify(res.data.data));
            res.data.data.map((item: any) => {
              if (item.type === "series") {
                item.type = "職場技能";
              } else if (item.type === "theme" || item.type === "B") {
                item.type = "主題週課";
              }
              let buffer = this.classTimeConvert(
                item.startClassDate,
                item.endClassDate
              );
              item.classDate = buffer.classDate;
              item.status = this.classStatusCalc(
                buffer.startTime,
                buffer.endTime,
                item.isReserved
              )!;
            });
            // 保存轉換後的資料
            this.courseInfos = res.data.data;
            this.isPending = false;
            this.systemPreferenceGet().then((res) => {
              if (res?.data.data.preference === null) {
                return;
              }
              if (
                res?.data.data.preference.history.timestamp ===
                new Date().getMonth() + 1
              ) {
                this.answerCategory =
                  res?.data.data.preference.history.category;
                this.selectedTitle = res?.data.data.preference.history.title;
                this.onlyWrongStatus =
                  res?.data.data.preference.history.onlyWrong;
                this.questionSelectedIndex =
                  res?.data.data.preference.history.index;
                this.answerFilterRestore({
                  category: res?.data.data.preference.history.category,
                  title: res?.data.data.preference.history.title,
                  onlyWrong: res?.data.data.preference.history.onlyWrong,
                  index: res?.data.data.preference.history.index,
                });
              }
            });
          }
        });
    },
    splidePerPageCalc() {
      let screenWidth = window.innerWidth;
      if (screenWidth >= 1280) {
        this.recommendedCourseSplideOptions.perPage = 3;
      } else if (screenWidth >= 1024 && screenWidth <= 1280) {
        this.recommendedCourseSplideOptions.perPage = 2;
      } else if (screenWidth >= 768 && screenWidth <= 1023) {
        this.recommendedCourseSplideOptions.perPage = 2;
      } else {
        this.recommendedCourseSplideOptions.perPage = 1;
      }
    },
    async ailearnCategoryGet() {
      try {
        return await ailearnCategoryGet();
      } catch (error) {
        console.error("錯誤", error);
      }
    },
    async ailearnRecordGet() {
      try {
        let date: {
          year: number | string;
          month: number | string;
          firstDay: number | string;
          lastDay: number | string;
        } = this.getLastDate();
        if (Number(date.month) < 10) {
          date.month = `0${date.month}`;
        }
        return await ailearnRecordGet(`${date.year}-${date.month}`);
      } catch (error) {
        console.error("錯誤", error);
      }
    },
    // page 對應到 index
    splideGoToPage(page: number, refs: any) {
      refs.splide.go(page);
    },
    answerSelector(index: number) {
      this.splideGoToPage(index, this.$refs.AnswerContentSplide);
      this.questionSelectedIndex = index;
    },
    // 接收到只顯示答錯題目的選項
    onlyWrong(status: boolean) {
      this.onlyWrongStatus = status;
    },
    // 將 API 取得到的所有問題處理格式
    // arr 是取得到的問題陣列、 onlyWrong 是接收到是否打勾只顯示答錯題目， filterOption 則是顯示類別
    questionsDataProcessing(arr: any, onlyWrong?: boolean, category?: string) {
      let buffer = JSON.parse(JSON.stringify(arr));
      buffer.forEach((item: any, index: number) => {
        item.index = index + 1;
        item.quantity = arr.length;
        // console.log(item);
        item.options.forEach((option: any, index: number) => {
          let status = -1;
          // status 說明： 1 非選擇， 2 選中且正確， 3 未選中但正確， 4 選中但錯誤
          if (item.userAnswer === option.option && item.isCurrent) {
            option.status = 2;
          } else if (item.answer === option.option && !item.isCurrent) {
            option.status = 3;
          } else if (item.userAnswer === option.option && !item.isCurrent) {
            option.status = 4;
          } else {
            option.status = 1;
          }
        });
      });
      if (onlyWrong) {
        buffer = buffer.filter((item: any) => item.isCurrent === false);
      }

      if (category !== "ALL") {
        buffer = buffer.filter((item: any) => item.category === category);
      }
      return buffer;
    },
    answerSelectFilter(category: string, title: string) {
      this.allCorrectTitle = title;
      this.selectedTitle = title;
      this.answerCategory = category;
      this.actualQuestionContent = this.questionsDataProcessing(
        this.allQuestionContent,
        this.onlyWrongStatus,
        this.answerCategory
      );
      if (this.actualQuestionContent.length > 0) {
        setTimeout(() => {
          this.splideGoToPage(0, this.$refs.AnswerContentSplide);
          this.questionSelectedIndex = 0;
        }, 10);
      }
    },
    analysisRateSelector(category: string, title: string) {
      this.analysisRateSelectedTitle = category;
      this.analysisRateTitle = title;
    },
    splideNowIndex(newIndex: number, pre: number, dest: number) {
      this.questionSelectedIndex = pre;
    },
    answerFilterRestore(data: {
      category: string;
      title: string;
      onlyWrong: boolean;
      index: number;
    }) {
      // this.answerCategory = data.category;
      // this.selectedTitle = data.title;
      // this.onlyWrongStatus = data.onlyWrong;
      // this.questionSelectedIndex = data.index;

      this.answerSelectFilter(data.category, data.title);
      this.onlyWrong(data.onlyWrong);
      // (只顯示答錯題目勾選狀態 , 上次選擇到的 selector , )
      (this.$refs.AnswerTitleSelector as any).filterRestore(
        data.onlyWrong,
        data.index
      );
      setTimeout(() => {
        if (this.actualQuestionContent.length > 0) {
          this.splideGoToPage(data.index, this.$refs.AnswerContentSplide);
          // this.questionSelectedIndex = data.index;
        }
      }, 10);
    },
    answerFilterRestoreTrigger() {
      this.systemPreferenceGet().then((res) => {
        if (res?.data.data.preference === null) {
          return;
        }
        if (
          res?.data.data.preference.history.timestamp ===
          new Date().getMonth() + 1
        ) {
          this.answerCategory = res?.data.data.preference.history.category;
          this.selectedTitle = res?.data.data.preference.history.title;
          this.onlyWrongStatus = res?.data.data.preference.history.onlyWrong;
          this.questionSelectedIndex = res?.data.data.preference.history.index;
          this.answerFilterRestore({
            category: res?.data.data.preference.history.category,
            title: res?.data.data.preference.history.title,
            onlyWrong: res?.data.data.preference.history.onlyWrong,
            index: res?.data.data.preference.history.index,
          });
        }
      });
    },
    async systemPreferenceGet() {
      try {
        return await systemPreferenceGet();
      } catch (error) {
        console.error("錯誤", error);
      }
    },
    // category : 題目類型英文縮寫， title : 課程類型英文加上中文
    // onlyWrong : 有無勾選只顯示答錯題目， index : 選擇第幾題
    async systemPreferenceSet(data: {
      category: string;
      title: string;
      onlyWrong: boolean;
      index: number;
    }) {
      try {
        return await systemPreferenceSet(data);
      } catch (error) {
        console.error("錯誤", error);
      }
    },
    systemPreferenceSetLimit() {
      if (!this.systemPreferenceSetting) {
        this.systemPreferenceSetting = true;
        this.systemPreferenceSet({
          category: this.answerCategory,
          title: this.selectedTitle,
          onlyWrong: this.onlyWrongStatus,
          index: this.questionSelectedIndex,
        }).then(() => {
          this.systemPreferenceSetting = false;
        });
      }
    },
  },
});
