
import { defineComponent } from "vue";
import mixins from "@/mixins/index";

export default defineComponent({
  name: "CourseCountCardItem",
  props: ["cardItem", "itemInfo", "itemType", "index"],
  mixins: [mixins],
  data() {
    return {};
  },
  methods: {
    SetPath(type: number) {
      return `/learning-report?category=3&type=${this.typeMap(type)}`;
    },
    typeMap(type: number) {
      if (type === 14) {
        return 9;
      } else if (type === 15) {
        return 10;
      } else if (type === 16) {
        return 11;
      } else {
        return type;
      }
    },
    goToClassHistory(path: string) {
      this.$router.push(path);
    },
    getClassNames(itemType: number) {
      if (itemType === 1) {
        return "content-card-right-col-div";
      } else if (itemType === 2) {
        return "";
      } else {
        return "";
      }
    },
  },
});
