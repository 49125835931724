
import { defineComponent } from "vue";
import mixins from "@/mixins/index";

import CourseCountCardItem from "@/components/LearningReport/courseCount/CourseCountCardItem.vue";

interface CourseCountInfo {
  type: string;
  used: number;
  total: number;
}

export default defineComponent({
  name: "LearningReportCourseCount",
  components: {
    CourseCountCardItem,
  },
  mixins: [mixins],
  props: [
    "cardInfo",
    "usedPercentageLength",
    "expirationDateLength",
    "activeLanguage",
  ],
  data() {
    return {
      isRotated: false,
    };
  },
  methods: {
    calcUsed(data: Array<CourseCountInfo>) {
      var used = 0;
      var total = 0;
      if (data !== undefined) {
        data.forEach((element) => {
          used += element.used;
          total += element.total;
        });
      }
      return [
        `${used} / ${total}`,
        100 - parseFloat((used / total).toFixed(3)) * 100,
      ];
    },
    toggleRotation() {
      this.isRotated = !this.isRotated;
    },
  },
});
