import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/icon_arrow_left_black.svg'
import _imports_1 from '@/assets/icons/icon_arrow_left_primary.svg'
import _imports_2 from '@/assets/icons/icon_arrow_right_black.svg'
import _imports_3 from '@/assets/icons/icon_arrow_right_primary.svg'


const _withScopeId = n => (_pushScopeId("data-v-39706936"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "correct-answer-rate-container" }
const _hoisted_2 = { class: "total-container" }
const _hoisted_3 = {
  class: "custom-wrapper",
  style: {"cursor":"grab"}
}
const _hoisted_4 = { class: "d-flex justify-content-center" }
const _hoisted_5 = { class: "splide__arrows" }
const _hoisted_6 = {
  src: _imports_0,
  alt: ""
}
const _hoisted_7 = {
  src: _imports_1,
  alt: ""
}
const _hoisted_8 = {
  src: _imports_2,
  alt: ""
}
const _hoisted_9 = {
  src: _imports_3,
  alt: ""
}
const _hoisted_10 = { class: "statistical-period text-black-tertiary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnalysisCard = _resolveComponent("AnalysisCard")!
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_CorrectAnswerTypeContent = _resolveComponent("CorrectAnswerTypeContent")!
  const _component_SplideTrack = _resolveComponent("SplideTrack")!
  const _component_Splide = _resolveComponent("Splide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Splide, {
        "has-track": false,
        options: _ctx.splideOptions,
        "aria-label": "...",
        ref: "analysisRateSplide",
        "onSplide:moved": _ctx.changeRateContent
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SplideTrack, null, {
              default: _withCtx(() => [
                _createVNode(_component_SplideSlide, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_AnalysisCard, { cardInfo: _ctx.cardInfo }, null, 8, ["cardInfo"])
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardInfo, (item, index) => {
                  return (_openBlock(), _createBlock(_component_SplideSlide, { key: index }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_CorrectAnswerTypeContent, { info: item }, null, 8, ["info"])
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                class: "splide__arrow splide__arrow--prev",
                onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.leftOn = true)),
                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.leftOn = false))
              }, [
                _withDirectives(_createElementVNode("img", _hoisted_6, null, 512), [
                  [_vShow, !_ctx.leftOn]
                ]),
                _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
                  [_vShow, _ctx.leftOn]
                ])
              ], 32),
              _createElementVNode("button", {
                class: "splide__arrow splide__arrow--next",
                onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rightOn = true)),
                onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.rightOn = false))
              }, [
                _withDirectives(_createElementVNode("img", _hoisted_8, null, 512), [
                  [_vShow, !_ctx.rightOn]
                ]),
                _withDirectives(_createElementVNode("img", _hoisted_9, null, 512), [
                  [_vShow, _ctx.rightOn]
                ])
              ], 32)
            ])
          ])
        ]),
        _: 1
      }, 8, ["options", "onSplide:moved"])
    ]),
    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getLastMonthInfo), 1)
  ]))
}