
import { defineComponent } from "vue";
import mixins from "@/mixins/index";

export default defineComponent({
  name: "LearningReportLanguageSwitch",
  props: ["activeLanguage", "langList"],
  mixins: [mixins],
  // emits: ['changeLanguage']
});
