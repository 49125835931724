
import { defineComponent } from "vue";
import mixins from "@/mixins/index";
import Bus from "@/utils/Bus";
// import { AnalysisInfo } from "@/types/models";
import AnalysisCardItem from "@/components/LearningReport/analysis/AnalysisCardItem.vue";
export default defineComponent({
  name: "LearningReportAnalysisCard",
  mixins: [mixins],
  components: { AnalysisCardItem },
  data() {
    return {
      windowWidth: window.innerWidth as number,
    };
  },

  props: ["cardInfo"],
  methods: {
    toggleAI() {
      Bus.emit("toggleWidget", "AiQuiz");
    },
    setBarColor(num: number) {
      if (num == 0) {
        return 1;
      } else if (num % 2 == 0) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  computed: {
    getLastMonth() {
      const d = new Date();
      let month = d.getMonth();
      if (month == 0) {
        // 1 月
        return "12";
      } else {
        if (month.toString.length == 1) {
          return `0${month}`;
        } else {
          return `${month}`;
        }
      }
    },
    getLastMonthEnd() {
      var thisYear = new Date().getFullYear();
      var longerMonth = ["01", "03", "05", "07", "08", "10", "12"];
      if (this.getLastMonth == "02") {
        if ((thisYear % 4 == 0 && thisYear % 100 != 0) || thisYear % 400 == 0) {
          return "29";
        } else {
          return "28";
        }
      } else if (longerMonth.includes(this.getLastMonth)) {
        return "31";
      } else {
        return "30";
      }
    },
  },
});
