
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import axios from "axios";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "NumberSelector",
  components: {},
  mixins: [],
  props: ["info", "status", "index", "selectedNumber"],
  emits: ["selected"],
  data() {
    return {};
  },
  computed: {
    // actionClass() {
    //   return {
    //     correct: this.status === "correct",
    //     "correct-selected": this.status === "correct-selected",
    //     wrong: this.status === "wrong",
    //     "wrong-selected": this.status === "wrong-selected",
    //   };
    // },
    actionClass() {
      if (this.selectedNumber === this.index && this.info.isCurrent) {
        return "correct-selected";
      } else if (this.selectedNumber === this.index && !this.info.isCurrent) {
        return "wrong-selected";
      } else if (this.selectedNumber !== this.index && this.info.isCurrent) {
        return "correct";
      } else if (this.selectedNumber !== this.index && !this.info.isCurrent) {
        return "wrong";
      }

      return "correct";
    },
  },
  watch: {},
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    // 
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    select(index: number) {
      this.$emit("selected", index);
    },
  },
});
