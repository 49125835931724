
import { defineComponent } from "vue";
import mixins from "@/mixins/index";
import ClassRecordItem from "@/components/LessonRecords/RecordItem.vue";
import ClassRecordPagination from "@/components/LessonRecords/Pagination.vue";

export default defineComponent({
  name: "ClassRecord",
  components: {
    ClassRecordItem,
    ClassRecordPagination,
  },
  data() {
    return {
      start: 0,
      end: 10,
      isLoading: true,
      mobile: false,
      noRecordInfo: true,
    };
  },

  props: ["activeCategory", "lessonRecordInfo", "lessonRecordSetting"],
  mixins: [mixins],
  methods: {
    pagechange(page: number) {
      if (window.innerWidth >= 1024) {
        this.start = 10 * (page - 1);
        this.end = 10 * page;
      }
    },
  },
  watch: {
    lessonRecordInfo: function () {
      if (window.innerWidth < 1024) {
        this.end = this.lessonRecordInfo.length;
      }
      if (this.lessonRecordInfo.length > 0) {
        this.noRecordInfo = true;
      } else {
        this.noRecordInfo = false;
      }
      this.isLoading = false;
    },
  },
  computed: {
    isMobileDevice() {
      return window.innerWidth < 1024;
    },
  },
});
