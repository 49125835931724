import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d69d5c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"16px","width":"47.5%"} }
const _hoisted_2 = { style: {"margin-top":"16px","width":"47.5%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MonthItem = _resolveComponent("MonthItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[0],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[1])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[1],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[2])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[2],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[3])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[3],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[4])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[4],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[5])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[5],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[5])
      }, null, 8, ["year", "month", "itemInfo"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[6],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[7])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[7],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[8])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[8],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[9])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[9],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[10])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[10],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[11])
      }, null, 8, ["year", "month", "itemInfo"]),
      _createVNode(_component_MonthItem, {
        year: _ctx.year,
        month: _ctx.months[11],
        itemInfo: _ctx.setParticipation(_ctx.cardInfo[12])
      }, null, 8, ["year", "month", "itemInfo"])
    ])
  ], 64))
}