import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-311d18e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-between month-row first-row" }
const _hoisted_2 = { class: "d-flex justify-content-between month-row second-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MonthItem = _resolveComponent("MonthItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (index) => {
        return _createVNode(_component_MonthItem, {
          key: index,
          month: _ctx.months[index -1 ],
          cardInfo: _ctx.setParticipation(_ctx.cardInfo[index])
        }, null, 8, ["month", "cardInfo"])
      }), 64))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (index) => {
        return _createVNode(_component_MonthItem, {
          key: index,
          month: _ctx.months[index + 5],
          cardInfo: _ctx.setParticipation(_ctx.cardInfo[index + 6])
        }, null, 8, ["month", "cardInfo"])
      }), 64))
    ])
  ], 64))
}