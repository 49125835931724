
import { defineComponent } from "vue";
import axios from "axios";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface actionAndPoints {
  action: string;
  point: string;
}

export default defineComponent({
  name: "LexicalCategory",
  components: {},
  props: [
    "modalId",
    "allTitle",
    "fadeContentId",
    "scrollBodyId",
    "options",
    "selectedTitle",
  ],
  emit: ["selectFilter"],
  data() {
    return {
      totalLexicalCategory: [
        "PRP - 介係詞用法",
        "CAU - 使役動詞",
        "CLA - 子句",
        "CON - 假設句",
        "NOU - 單字,文意理解",
        "ADJ - 比較級,形容詞",
        "GER - 不定詞,動名詞...",
        "REP - 間接句",
        "ACT - 動詞時態",
        "MOD - 助動詞",
        "BEE - be 動詞",
        "ADV - 副詞",
        "XXX - 少見句型(倒裝...)",
        "PAS - 被動時態",
        "CNJ - 連接詞",
        "PRD - 冠詞,代詞",
        "MEW - 量詞",
        "VER - 動詞",
      ],
    };
  },
  methods: {
    gradientEffect() {
      let target = document.querySelector(
        `#${this.fadeContentId}`
      )! as HTMLElement;
      let scrollBody = document.querySelector(
        `#${this.scrollBodyId}`
      )! as HTMLElement;
      // 這裡必須延遲 10ms 是為了避免 DOM 剛生成時無法正確取得高度
      setTimeout(() => {
        scrollBody.addEventListener("scroll", () => {
          // 多 +5 是為了避免誤差造成判斷錯誤
          if (
            scrollBody.scrollTop + scrollBody.clientHeight + 5 >=
            scrollBody.scrollHeight
          ) {
            target.classList.remove("fade-content");
            target.classList.add("no-fade-content");
          } else {
            target.classList.add("fade-content");
            target.classList.remove("no-fade-content");
          }
        });
      }, 10);
    },
    selectFilter(category: string, title: string) {
      this.$emit("selectFilter", category, title);
    },
  },
  mounted() {
    this.gradientEffect();
  },
});
