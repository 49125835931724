
import { defineComponent } from "vue";
import mixins from "@/mixins/index";
import { Modal } from "bootstrap";
import EnglishCheckLevel from "@/components/modal/EnglishCheckLevel.vue";

export default defineComponent({
  name: "LearningReportUser",
  components: {
    EnglishCheckLevel,
  },
  mixins: [mixins],
  props: ["userInfo", "activeLanguage", "dataLoading"],
  data() {
    return {
      englishCheckLevelModalId: "LearningReportEnglishCheckLevelModal",
      modalEnglishCheckLevel: null as any,
      englishData: {
        level: "--",
        reference: "",
        testLink: "",
      },
    };
  },
  methods: {
    openModalEnglishCheckLevel() {
      this.modalEnglishCheckLevel.show();
    },
    englishLevelReference(level: number) {
      if (level === 1 || level === null) {
        return "";
      } else if (level === 2) {
        return "TOEIC 300~400";
      } else if (level === 3) {
        return "TOEIC 400~550";
      } else if (level === 4) {
        return "TOEIC 550~600";
      } else if (level === 5) {
        return "TOEIC 600~650";
      } else if (level === 6) {
        return "TOEIC 650~730";
      } else if (level === 7) {
        return "TOEIC 730~800";
      } else if (level === 8) {
        return "TOEIC 800~850";
      } else if (level === 9) {
        return "TOEIC 850+";
      }
    },
  },
  mounted() {
    // Modal 建立
    this.modalEnglishCheckLevel = new Modal(
      document.getElementById(this.englishCheckLevelModalId)! as HTMLElement
    );
  },
  beforeUnmount() {
    this.modalEnglishCheckLevel = null;
  },
  watch: {
    userInfo() {
      if (this.userInfo.levelAssessment.en.level !== null) {
        this.englishData.level = `L${this.userInfo.levelAssessment.en.level}`;
        this.englishData.reference =
          this.englishLevelReference(this.userInfo.levelAssessment.en.level) ||
          "";
      }
      this.englishData.testLink = this.userInfo.levelAssessment.en.link;
    },
  },
});
