
import { defineComponent } from "vue";
import mixins from "@/mixins/index";

export default defineComponent({
  name: "LessonRecordsItem",
  props: ["itemInfo"],
  mixins: [mixins],
  data() {
    return {
      date: "",
      time: "",
    };
  },
  methods: {
    setFlag(lang: string) {
      return require(`@/assets/icons/flags/flag_${lang}.svg`);
    },
    // AM / PM 判斷
    getPeriod(timeRange: string): string {
      const times = timeRange.split(" - ");
      const startTime = times[0];
      const [startHourString, startMinuteString] = startTime.split(":");
      const startHour = parseInt(startHourString, 10);

      if (startHour >= 12) {
        return "PM";
      } else {
        return "AM";
      }
    },
    // 日期格式轉換
    formatDate(dateString: string): string {
      const regex = /(\d{4})\/(\d{2})\/(\d{2})/;
      return dateString.replace(regex, "$1-$2-$3");
    },
  },
  mounted() {
    const period = this.getPeriod(
      this.getClassTime(this.itemInfo.startClassDate, this.itemInfo.type)
    );

    // 日期
    this.date = this.formatDate(
      this.timestampToDate(this.itemInfo.startClassDate, this.itemInfo.type)
    );
    // 時間
    this.time = `${period} ${
      this.classTimeConvert(
        this.itemInfo.startClassDate,
        this.itemInfo.endClassDate
      ).startToEnd
    }`;
  },
});
