
import { defineComponent } from "vue";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import AnalysisCard from "@/components/LearningReport/analysis/AnalysisCard.vue";
import CorrectAnswerTypeContent from "@/components/LearningReport/MonthlyReport/CorrectAnswerTypeContent.vue";
import { Modal } from "bootstrap";
import axios from "axios";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
import mixins from "@/mixins/index";

export default defineComponent({
  name: "CorrectAnswerRateContent",
  components: {
    Splide,
    SplideSlide,
    SplideTrack,
    AnalysisCard,
    CorrectAnswerTypeContent,
  },
  mixins: [mixins],
  props: ["cardInfo", "selectedTitle"],
  emits: ["splideChangeTitle"],
  data() {
    return {
      splideOptions: {
        perPage: 1,
        perMove: 1,
        pagination: false,
        autoHeight: true,
      },
      leftOn: false,
      rightOn: false,
    };
  },
  computed: {
    getLastMonthInfo() {
      // const today = new Date();
      // const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      // const firstDayOfMonth = new Date(
      //   lastMonth.getFullYear(),
      //   lastMonth.getMonth(),
      //   1
      // );
      // const lastDayOfMonth = new Date(
      //   lastMonth.getFullYear(),
      //   lastMonth.getMonth() + 1,
      //   0
      // );

      // const year = lastMonth.getFullYear();
      // const month = lastMonth.getMonth() + 1;
      // const firstDay = firstDayOfMonth.getDate();
      // const lastDay = lastDayOfMonth.getDate();
      let date = this.getLastDate();
      return `統計依據為 ${date.year}年 ${date.month}月 ${date.firstDay}日~ ${date.month}月 ${date.lastDay}日 答題統計分析`;
    },
  },
  watch: {
    selectedTitle() {
      let buffer = this.cardInfo;
      buffer.sort((a: any, b: any) => b.hitRate - a.hitRate);
      const index = buffer.findIndex(
        (item: any) => item.en === this.selectedTitle
      );
      this.splideGoToPage(index + 1, this.$refs.analysisRateSplide);
    },
  },
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    //
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    // page 對應到 index
    splideGoToPage(page: number, refs: any) {
      refs.splide.go(page);
    },
    changeRateContent(newIndex: number, pre: number, dest: number) {
      let buffer = "";
      if (pre === 0) {
        buffer = "18類題型分析";
        this.$emit("splideChangeTitle", "18類題型分析", "18類題型分析");
      } else {
        buffer = `${this.cardInfo[pre - 1].en} - ${this.cardInfo[pre - 1].ch}`;
        this.$emit("splideChangeTitle", this.cardInfo[pre - 1].en, buffer);
      }
    },
  },
});
