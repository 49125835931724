
import { defineComponent } from "vue";
import NumberSelector from "@/components/LearningReport/MonthlyReport/NumberSelector.vue";
import LexicalCategory from "@/components/modal/LexicalCategory.vue";
import FilterOption from "@/components/LearningReport/MonthlyReport/FilterOption.vue";
import { Modal } from "bootstrap";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import mixins from "@/mixins/index";

export default defineComponent({
  name: "AnswerTitleSelector",
  components: {
    NumberSelector,
    LexicalCategory,
    Splide,
    SplideSlide,
    SplideTrack,
    FilterOption,
  },
  mixins: [mixins],
  props: [
    "answerStatus",
    "filterData",
    "questionContent",
    "questionQuantity",
    "selectedTitle",
    "questionSelectedIndex",
  ],
  emits: ["selected", "onlyWrongStatus", "selectFilter"],
  data() {
    return {
      splideOptions: {
        pagination: false,
        flickPower: 100,
        type: "slide",
        drag: "free",
        perPage: 1,
      },
      leftOn: false,
      rightOn: false,
      modalId: "lexicalCategoryModal",
      myModal: null as any,
      selectedNumber: 0,
      onlyWrong: false,
    };
  },
  computed: {
    lastMonth() {
      let date = this.getLastDate();
      return date.month;
    },
  },
  watch: {
    onlyWrong() {
      this.$emit("onlyWrongStatus", this.onlyWrong);
    },
    questionSelectedIndex() {
      if (this.questionSelectedIndex === 0) {
        this.selectedNumber = 0;
        this.$emit("selected", 0);
      } else {
        this.selectedNumber = this.questionSelectedIndex;
      }
    },
  },
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    this.splidePerPageCalc();
    // addEventListener("resize", () => {
    //   this.splidePerPageCalc();
    // });
    this.myModal = new Modal(
      document.getElementById(`${this.modalId}`) as HTMLElement
    );
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    splidePerPageCalc() {
      let screenWidth = window.innerWidth;
      if (screenWidth >= 1280) {
        this.splideOptions.perPage = 15;
      } else if (screenWidth >= 1024 && screenWidth <= 1280) {
        this.splideOptions.perPage = 13;
      } else if (screenWidth >= 768 && screenWidth <= 1024) {
        this.splideOptions.perPage = 11;
      }
    },
    showModal() {
      this.myModal.show();
    },
    selected(index: number) {
      this.selectedNumber = index;
      this.$emit("selected", index);
    },
    selectFilter(category: string, title: string) {
      this.$emit("selectFilter", category, title);
    },
    filterRestore(status: boolean, selector: number) {
      this.onlyWrong = status;
      this.selectedNumber = selector;
    },
  },
});
