import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3651d3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mobile-hide" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["for", "onClick"]
const _hoisted_6 = ["name", "id"]
const _hoisted_7 = { class: "btn-item d-flex align-items-center" }
const _hoisted_8 = { class: "text-black-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal",
    id: _ctx.modalId,
    tabindex: "-1",
    "aria-labelledby": "exampleModalLabel",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "modal-content fade-content",
        id: _ctx.fadeContentId
      }, [
        _createElementVNode("div", {
          class: "modal-body",
          id: _ctx.scrollBodyId
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
            return (_openBlock(), _createElementBlock("label", {
              key: index,
              for: `${_ctx.modalId}-${index.toString()}`,
              class: "all-selected text-black-tertiary d-flex align-items-center",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              onClick: ($event: any) => (_ctx.selectFilter(item.category, item.title))
            }, [
              _createElementVNode("input", {
                name: _ctx.modalId,
                id: `${_ctx.modalId}-${index.toString()}`,
                type: "radio",
                class: "d-none"
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(item.title), 1)
              ])
            ], 8, _hoisted_5))
          }), 128))
        ], 8, _hoisted_4)
      ], 8, _hoisted_3)
    ])
  ], 8, _hoisted_1))
}