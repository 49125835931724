import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bb708f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select" }
const _hoisted_2 = { class: "option" }
const _hoisted_3 = { class: "answer" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["outer-container d-flex", {
      ' text-black-tertiary': _ctx.info.status === 1,
      ' bg-primary-light text-primary-dark':
        _ctx.info.status === 2 || _ctx.info.status === 3,
      'bg-wrong text-red-danger-dark': _ctx.info.status === 4,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["circle", {
          ' not-selected': _ctx.info.status === 1,
          ' correct': _ctx.info.status === 2 || _ctx.info.status === 3,
          wrong: _ctx.info.status === 4,
        }])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["point", {
          'correct-point': _ctx.info.status === 2,
          'wrong-point': _ctx.info.status === 4,
        }])
      }, null, 2)
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.info.option) + ".", 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.info.text), 1),
    (_ctx.info.status !== 1)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "answer-status",
          src: 
        _ctx.info.status === 2 || _ctx.info.status === 3
          ? _ctx.answerCurrentIcon
          : _ctx.answerWrongIcon
      ,
          alt: ""
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 2))
}