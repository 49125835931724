
import { defineComponent } from "vue";
import MonthItem from "@/components/LearningReport/participation/ParticipationMonth.vue";

export default defineComponent({
  name: "LearningReportParticipationYear",
  components: { MonthItem },
  props: ["cardInfo"],
  methods: {
    setParticipation(num: number | null) {
      if (num == null) {
        return "NaN";
      } else {
        return `${num}%`;
      }
    },
  },
  data() {
    return {
      months: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
    };
  },
});
