import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64be8264"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryCard = _resolveComponent("CategoryCard")!
  const _component_AltTopCard = _resolveComponent("AltTopCard")!
  const _component_LanguageSwitch = _resolveComponent("LanguageSwitch")!
  const _component_UserCard = _resolveComponent("UserCard")!
  const _component_PostRank = _resolveComponent("PostRank")!
  const _component_CourseCountCard = _resolveComponent("CourseCountCard")!
  const _component_StudyCountCard = _resolveComponent("StudyCountCard")!
  const _component_ParticipationCard = _resolveComponent("ParticipationCard")!
  const _component_AnswerAnalysis = _resolveComponent("AnswerAnalysis")!
  const _component_Template518 = _resolveComponent("Template518")!
  const _component_ClassRecord = _resolveComponent("ClassRecord")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Template518, null, _createSlots({
      "right-column": _withCtx(() => [
        ([0, 1].includes(_ctx.activeCategory))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_AltTopCard, {
                onChangeLanguage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateLanguage($event))),
                userInfo: _ctx.userInfo,
                activeCategory: _ctx.activeCategory,
                activeLanguage: _ctx.activeLanguage
              }, null, 8, ["userInfo", "activeCategory", "activeLanguage"]),
              _createVNode(_component_LanguageSwitch, {
                onChangeLanguage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateLanguage($event))),
                activeLanguage: _ctx.activeLanguage,
                langList: _ctx.userInfo
              }, null, 8, ["activeLanguage", "langList"]),
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_UserCard, {
                  userInfo: _ctx.userInfo,
                  activeLanguage: _ctx.activeLanguage,
                  dataLoading: _ctx.userCardDataLoaging
                }, null, 8, ["userInfo", "activeLanguage", "dataLoading"]),
                _createVNode(_component_PostRank, {
                  dataLoading: _ctx.rankDataLoaging,
                  data: _ctx.postRankData
                }, null, 8, ["dataLoading", "data"]),
                _createVNode(_component_CourseCountCard, {
                  cardInfo: _ctx.courseCountInfo,
                  usedPercentageLength: _ctx.usedPercentageLength,
                  expirationDateLength: _ctx.expirationDateLength,
                  activeLanguage: _ctx.activeLanguage
                }, null, 8, ["cardInfo", "usedPercentageLength", "expirationDateLength", "activeLanguage"]),
                _createVNode(_component_StudyCountCard, {
                  cardInfo: _ctx.studyCountInfo,
                  participationInfo: _ctx.participationInfo
                }, null, 8, ["cardInfo", "participationInfo"]),
                _createVNode(_component_ParticipationCard, {
                  cardInfo: _ctx.participationInfo,
                  activeYear: _ctx.activeYear,
                  onChangeYear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateYear($event)))
                }, null, 8, ["cardInfo", "activeYear"])
              ], 512), [
                [_vShow, _ctx.activeCategory == 0]
              ]),
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_AnswerAnalysis, {
                  cardInfo: _ctx.analysisInfo,
                  activeLanguage: _ctx.activeLanguage
                }, null, 8, ["cardInfo", "activeLanguage"])
              ], 512), [
                [_vShow, _ctx.activeCategory == 1]
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.activeCategory !== 3)
        ? {
            name: "left-column",
            fn: _withCtx(() => [
              _createVNode(_component_CategoryCard, { activeCategory: _ctx.activeCategory }, null, 8, ["activeCategory"])
            ])
          }
        : undefined
    ]), 1024),
    (_ctx.activeCategory == 3)
      ? (_openBlock(), _createBlock(_component_ClassRecord, {
          key: 0,
          activeCategory: _ctx.activeCategory,
          lessonRecordInfo: _ctx.lessonRecordInfo,
          lessonRecordSetting: _ctx.lessonRecordSetting
        }, null, 8, ["activeCategory", "lessonRecordInfo", "lessonRecordSetting"]))
      : _createCommentVNode("", true)
  ], 64))
}