
import { defineComponent } from "vue";

export default defineComponent({
  name: "LearningReportAnalysisCardItem",
  props: ["itemTitle", "itemStats", "barColor", "index"],
  methods: {
    coloredBar(percentage: number) {
      return { "--width": `${percentage}%` };
    },
    whiteBar(percentage: number) {
      return { "--width": `${percentage + 2}%` };
    },
  },
});
