
import { defineComponent, PropType } from "vue";
import YearItem from "@/components/LearningReport/participation/ParticipationYear.vue";

interface yearItemInfo {
  year: number;
  data: {
    "12": number;
    "11": number;
    "10": number;
    "9": number;
    "8": number;
    "7": number;
    "6": number;
    "5": number;
    "4": number;
    "3": number;
    "2": number;
    "1": number;
  };
}
export default defineComponent({
  name: "LearningReportParticipationCard",
  components: { YearItem },
  props: {
    activeYear: {
      type: Number,
      required: true,
    },
    cardInfo: {
      type: Array as PropType<Array<yearItemInfo>> | null,
      required: true,
    },
  },
  methods: {
    changeYear(year: number) {
      this.$emit("changeYear", year);
    },
    yearItemInfo() {
      let result = {};
      this.cardInfo.forEach((ele: yearItemInfo) => {
        if (ele.year == this.activeYear) {
          result = ele.data;
        }
      });

      return result;
    },
  },
});
