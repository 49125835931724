import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d028b9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-item text-black-tertiary d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", _hoisted_1, [
      _createElementVNode("p", {
        class: _normalizeClass(["filter-option", _ctx.info.id === -1 ? 'font-weight-500 text-black-secondary' : ''])
      }, _toDisplayString(_ctx.info.title), 3)
    ])
  ]))
}