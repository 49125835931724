
import { defineComponent } from "vue";

export default defineComponent({
  name: "LearningReportCategoryCard",
  props: ["activeCategory"],
  computed: {
    iconFilled() {
      if (this.activeCategory === 0 || this.activeCategory === 3) {
        return require("@/assets/icons/learningReport/icon_filled_active.svg");
      } else {
        return require("@/assets/icons/learningReport/icon_filled.svg");
      }
    },
    iconOrderList() {
      if (this.activeCategory === 1) {
        return require("@/assets/icons/learningReport/icon_order_list_active.svg");
      } else {
        return require("@/assets/icons/learningReport/icon_order_list.svg");
      }
    },
  },
});
