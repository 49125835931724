
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import RankRule from "@/components/modal/RankRule.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "LearningReportUserCard",
  components: {
    RankRule,
  },
  props: ["dataLoading", "data"],
  data() {
    return {
      dotFlashIndex: 0,
      rankRuleModalId: "LearningReportPageRankRuleModal",
      modalRankRule: null as any,
      activityData: {
        activityStatus: true,
        banner: "https://static-cse.canva.cn/blob/172614/banner5.png",
        // banner: "https://static-cse.canva.cn/blob/172605/1.png",
        bannerType: "",
        // 活動標題
        title: "OurScool 第二季「積分活動獎勵賽」火熱進行中！",
        // 活動敘述
        description: "積分活動獎勵賽 2023-03-01~2023-06-30，前往活動內容 >",
        // 手機板活動敘述
        descriptionMobile: [
          "積分活動獎勵賽",
          "2023-03-01~2023-06-30",
          "前往活動內容 >",
        ],
        // 活動宣傳要導向的連結
        promotionalLink: "https://yahoo.com.tw",
        // 累計積分
        cumulativePoints: 12111,
        // 活動積分
        seasonalPoints: 6666,
        // 活動排名 %
        eventRanking: 25,
        // 領先百分比 %
        leadPercentage: 75,
        // 參與總人數
        participationCount: 99999,
        // 抽獎資格 %
        qualificationForLottery: 15,
        // 獎品名稱
        awardName: "任天堂Nintendo Switch 健身環大冒險同捆組",
        // 獎品圖片
        awardImg:
          "",
        // 倒數計時
        countdown: 0,
        // 活動截止日期
        eventDeadline: "",
        eventDeadlineTime: "",
        endTime: "2023-06-11 00:00:00",
      },
      countdownStatus: false,
    };
  },
  methods: {
    bannerSet() {
      if (this.activityData.banner === null) {
        return "";
      } else {
        if (this.activityData.bannerType === "color") {
          return `background: ${this.activityData.banner}`;
        } else if (this.activityData.bannerType === "image") {
          return `background-image: url('${this.activityData.banner}')`;
        }
      }
    },
    animationDotFlash() {
      this.dotFlashIndex = this.dotFlashIndex + 1;
      if (this.dotFlashIndex > 1) {
        this.dotFlashIndex = 0;
      }
      let array = [
        "radial-gradient(50% 50% at 50% 50%, #FFC4C4 0%, rgba(254, 143, 143, 0) 100%)",
        "radial-gradient(50% 50% at 50% 50%, rgba(255, 121, 121, 0.42) 0%, rgba(254, 143, 143, 0) 100%)",
      ];
      let opacityArray = ["1", "0.4"];
      let targets = document.querySelectorAll(
        ".flash-halo"
      ) as NodeListOf<HTMLElement>;
      targets.forEach((target) => {
        // target.style.background = array[this.dotFlashIndex];
        target.style.opacity = opacityArray[this.dotFlashIndex];
      });
    },
    openModalRankRule() {
      this.modalRankRule.show();
    },
    locatorPositionCalc(obj: {
      leadPercentage: number;
      qualificationForLottery: number;
    }) {
      let flashDots = document.querySelectorAll(".locator-container");
      let progressLines = document.querySelectorAll(".progress-line");
      let inTheRankingContainers = document.querySelectorAll(
        ".in-the-ranking-container"
      );
      // 抽獎範圍虛線，手機板沒有
      let inTheRanking = {
        1440: ((100 - obj.qualificationForLottery) / 100) * 319.5 + 115,
        1280: ((100 - obj.qualificationForLottery) / 100) * 287 + 146,
        1024: ((100 - obj.qualificationForLottery) / 100) * 287 + 87.5,
        768: ((100 - obj.qualificationForLottery) / 100) * 287 + 87.5,
      };
      let screenWidth = window.innerWidth;

      if (screenWidth >= 1440) {
        let left = (obj.leadPercentage / 100) * 337.5 + 86;
        (flashDots[0] as HTMLElement).style.left = `${left}px`;
        (progressLines[0] as HTMLElement).style.background = `linear-gradient(
                to right,
                #909090 0%,
                #909090 ${
                  100 -
                  obj.qualificationForLottery -
                  ((100 - obj.qualificationForLottery) / 100) * 0.5
                }%,
                #25a2a6 ${
                  100 -
                  obj.qualificationForLottery -
                  ((100 - obj.qualificationForLottery) / 100) * 0.5
                }%,
                #25a2a6 100%
              )`;
        (
          inTheRankingContainers[0] as HTMLElement
        ).style.left = `${inTheRanking[1440]}px`;
      } else if (screenWidth >= 1280 && screenWidth <= 1439) {
        let left = (obj.leadPercentage / 100) * 305.5 + 117.5;
        (flashDots[0] as HTMLElement).style.left = `${left}px`;
        (progressLines[0] as HTMLElement).style.background = `linear-gradient(
                to right,
                #909090 0%,
                #909090 ${100 - obj.qualificationForLottery}%,
                #25a2a6 ${100 - obj.qualificationForLottery}%,
                #25a2a6 100%
              )`;
        (
          inTheRankingContainers[0] as HTMLElement
        ).style.left = `${inTheRanking[1280]}px`;
      } else if (screenWidth >= 1024 && screenWidth <= 1279) {
        let left = (obj.leadPercentage / 100) * 305 + 58.5;
        (flashDots[0] as HTMLElement).style.left = `${left}px`;
        (progressLines[0] as HTMLElement).style.background = `linear-gradient(
                to right,
                #909090 0%,
                #909090 ${100 - obj.qualificationForLottery}%,
                #25a2a6 ${100 - obj.qualificationForLottery}%,
                #25a2a6 100%
              )`;
        (
          inTheRankingContainers[0] as HTMLElement
        ).style.left = `${inTheRanking[1024]}px`;
      } else if (screenWidth >= 768 && screenWidth <= 1023) {
        let left = (obj.leadPercentage / 100) * 305.5 + 58.5;
        (flashDots[1] as HTMLElement).style.left = `${left}px`;
        (progressLines[1] as HTMLElement).style.background = `linear-gradient(
                to right,
                #909090 0%,
                #909090 ${100 - obj.qualificationForLottery}%,
                #25a2a6 ${100 - obj.qualificationForLottery}%,
                #25a2a6 100%
              )`;
        (
          inTheRankingContainers[1] as HTMLElement
        ).style.left = `${inTheRanking[768]}px`;
      } else if (screenWidth >= 415 && screenWidth <= 767) {
        let left = (obj.leadPercentage / 100) * 176.5 + 54;
        (flashDots[2] as HTMLElement).style.left = `${left}px`;
        (progressLines[2] as HTMLElement).style.background = `linear-gradient(
                to right,
                #909090 0%,
                #909090 ${100 - obj.qualificationForLottery}%,
                #25a2a6 ${100 - obj.qualificationForLottery}%,
                #25a2a6 100%
              )`;
      } else if (screenWidth <= 414) {
        let left = (obj.leadPercentage / 100) * 172 + 38;
        (flashDots[2] as HTMLElement).style.left = `${left}px`;
        (progressLines[2] as HTMLElement).style.background = `linear-gradient(
                to right,
                #909090 0%,
                #909090 ${100 - obj.qualificationForLottery}%,
                #25a2a6 ${100 - obj.qualificationForLottery}%,
                #25a2a6 100%
              )`;
      }
    },
    // 計算是否要執行倒數計時
    countdownCalc(endTime: string) {
      if (endTime.length <= 0 || this.countdownStatus === true) {
        return;
      }
      this.countdownStatus = true;
      const targetDate = new Date(endTime); // 設定指定的日期
      const currentTime = new Date().getTime(); // 獲取當前時間的毫秒數

      const timeDiff = targetDate.getTime() - currentTime; // 計算指定日期和當前時間的毫秒數差
      // 259200000 為 72 小時轉換成毫秒
      if (timeDiff <= 259200000 && timeDiff >= 0) {
        // 將毫秒數轉換為秒數，並將其設置為倒數計時器的剩餘時間
        this.activityData.countdown = Math.floor(timeDiff / 1000);
        // 啟動計時器，每秒更新一次剩餘時間
        setInterval(() => {
          this.activityData.countdown--;
          if (this.activityData.countdown === 0) {
            document.location.reload();
          }
        }, 1000);
      }
    },
    // 日期 - 轉換 /
    formatDate(dateString: string): string {
      return dateString.replace(/-/g, "/");
    },
    // 倒數計時時間格式化
    formatTime(time: number) {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = time % 60;

      // 使用 padStart 函數來填充數字，確保每個部分都是兩位數
      const formattedTime = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

      return formattedTime;
    },
  },
  mounted() {
    this.activityData = this.data;
    // Modal 建立
    this.modalRankRule = new Modal(
      document.getElementById(this.rankRuleModalId)! as HTMLElement
    );
    setInterval(() => {
      this.animationDotFlash();
    }, 1000);
  },
  beforeUnmount() {
    this.modalRankRule = null;
  },
  updated() {
    this.locatorPositionCalc({
      leadPercentage: this.activityData.leadPercentage,
      qualificationForLottery: this.activityData.qualificationForLottery,
    });
    this.countdownCalc(this.activityData.eventDeadlineTime);
  },
});
